<template>
  <div>
    <titleDiv>有赞核销</titleDiv>
    <div class="product-div">
      <div class="title-top">
        <h1 class="title-h1">有赞核销</h1>
        <div class="title-text">
          便捷高效的核销方案，支持虚拟商品、电子卡券、优惠券、优惠码、门店自提等类型商品。无需给予员工管理员权限、无需下载APP、只需打开微信，一键扫码核销
        </div>
      </div>
      <div class="content">
        <div class="content-card">
          <h1 class="content-card-heading">有赞线下核销方案</h1>
          <div class="content-card-bottom">
            <div>
              <div class="content-card-list">
                <div>绑定自有公众号、无需下载APP</div>
              </div>
              <div class="content-card-list">
                <div>无需给予员工管理员权限</div>
              </div>
              <div class="content-card-list">
                <div>支持多种类型商品</div>
              </div>
              <div class="content-card-list">
                <div>一键扫码核销</div>
              </div>
            </div>
          </div>
          <br/>
        </div>
      </div>
    </div>
    <footerDiv></footerDiv>
  </div>
</template>

<script>
  import titleDiv from "../index/title";
  import footerDiv from "../index/footer";

  export default {
    name: "product",
    components: {
      titleDiv,
      footerDiv
    }
  };
</script>

<style lang="scss" scoped>
  * {
    padding: 0;
    margin: 0;
    text-align: center;
  }

  a {
    text-decoration: none;
  }

  .product-div {
    max-width: 1280px;
    margin: 0 auto;
  }

  .title-top {
    margin: 3rem 1rem;
  }

  .title-h1 {
    font-size: 36px;
    color: #234689;
    font-weight: 600;
    line-height: 60px;
  }

  .title-text {
    font-size: 17px;
    color: #6d7d93;
    font-weight: 300;
  }

  .content {
    margin: 0 2rem 2rem;
  }

  .content-card {
    padding: 30px 0;
    box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.1);
    margin: auto;
    max-width: 425px;
    border-radius: 8px;
  }

  .content-card-img {
    max-width: 100%;
  }

  .discount {
    text-decoration: line-through;
    color: #7a7a7a;
    margin-top: 10px;
  }

  .content-card-heading {
    color: #527fe5;
    font-size: 28px;
    line-height: 50px;
    font-weight: 500;
  }

  .content-card-bottom {
    display: flex;
    justify-content: center;
    margin: 1rem 0;
  }

  .content-btn {
    font-size: 16px;
    color: white;
    background-color: #dd4814;
    padding: 8px 36px;
    border: none;
    border-radius: 4px;
    outline: none;
    cursor: pointer;
  }

  .content-btn:hover {
    background-color: rgba(221, 72, 20, 0.8);
    color: #f1f1f1;
  }

  .prc-bottom-img {
    width: 48px;
    height: 48px;
  }

  .content-card-list {
    display: flex;
    margin: 10px 0;
  }

  .img-right {
    width: 24px;
    padding-right: 10px;
  }

  .product-bottom {
    margin: 4rem 3rem;
    display: flex;
  }

  .prc-btm-list {
    width: 0;
    padding: 20px;
    flex-grow: 1;
    flex-shrink: 1;
  }

  .prc-bottom-text {
    font-size: 17px;
    color: #6d7d93;
    font-weight: 400;
  }

  .prc-bottom-text > a {
    color: #3273dc;
  }

  .prc-bottom-text > a:hover {
    color: #333;
  }

  @media screen and (max-width: 798px) {
    .product-bottom {
      flex-direction: column;
    }
    .prc-btm-list {
      width: auto;
      padding: 20px 0;
    }
  }
</style>
